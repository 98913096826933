import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class ManufacturerService {
  readonly url = environment.baseUrl + "Proizvodjaci/";
  constructor(private http: HttpClient) {}

  public getManufacturers() {
    return this.http.get(this.url + "GetAll");
  }

}
