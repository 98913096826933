export class Brand{
   brandId?:number;
   naziv?:string;
   opis?:string;
   image?:string;

   constructor(
    brandId?:string,
    image?: string,
    opis?:string,
    naziv?:string,


  ) {
    
    this.image = image;
    this.opis=opis;
    this.naziv=naziv;

  }
}
