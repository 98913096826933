import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RestService {
  readonly url = environment.baseUrl + "Narudzbe/";
  readonly url2=environment.baseUrl;

  constructor(private http: HttpClient) {}
  addNarudzba(narudzba: any) {
  //  console.log("full narudzba", narudzba);
    return this.http.post(this.url + "Add", narudzba);
  }
  getArtikliImages(id:any){
    return this.http.get(this.url2 + "Artikli/GetImages?artiklId="+id);
}
}
