import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductService } from '../../shared/services/product.service';
import { CartItem } from 'src/app/modals/cart-item';
import { HttpClient } from '@angular/common/http';
import { ProductDialogComponent } from './../products/product-dialog/product-dialog.component';
import { Router } from '@angular/router';
import { WishlistService } from '../../shared/services/wishlist.service';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { ManufacturerService } from '../../shared/services/manufacturer.service';

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss']
})
export class FoodComponent implements OnInit  {
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  products: Product[];
  productsNovi: Product[];
  productsTop: Product[];
  shoppingCartItems: CartItem[] = [];
  contentLoaded = false;
  defaultFruits: Product[];
  isMobile = false;
  public brands1: any[] = []
  public slides = [
    { title: 'THE BEST CHOICE IS HERE', subtitle: 'New Arrivals On Sale', image: 'assets/images/carousel/food/pc1.jpg' },
    { title: 'Biggest discount', subtitle: 'Check the promotion', image: 'assets/images/carousel/food/pc2.jpg' },
    { title: 'Biggest sale', subtitle: 'Dont miss it', image: 'assets/images/carousel/food/pc3.jpg' },

  ];

 
  constructor(private productService: ProductService, private wishlistService: WishlistService, private cartService: CartService, private dialog: MatDialog, private router: Router,private httpClient:HttpClient,private manufacturerService: ManufacturerService) { 
   
  }
 
  ngOnInit() {
    this.productService.getProducts().subscribe((product) => {
      this.products = product;
      //console.log('PROIZVODI_ ', this.products)
    });
    this.productService.getTopProducts().subscribe((product) => {
      this.productsTop = product;
     // console.log('PROIZVODI_TOP ', this.productsTop)
    });
    this.productService.getNoviProducts().subscribe((product) => {
      this.productsNovi = product;
      //console.log('PROIZVODI_NOVI ', this.productsNovi)
    });
    
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
    this.GetMethod();
  }

  public GetMethod() {
    this.manufacturerService.getManufacturers().subscribe((data: any) => {
      this.brands1 = data;
    //  console.log("brandovi", this.brands1);
    });
  }
  
}
