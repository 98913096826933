import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscriber } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { slider } from "src/app/modals/slider.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class slide {
  readonly url = environment.baseUrl + "Klijenti/";
  public observer: Subscriber<{}>;
  constructor(private httpClient: HttpClient) {}
  //   getSlide(){
  //       return this.httpClient.get('https://localhost:44356/api/Klijenti/GetImages');
  //   }

  public getSlider(): Observable<slider[]> {
    return this.httpClient
      .get<any>(this.url + "GetImages") 
      .pipe(
        map((data) => {
          return data?.map((value: any) => ({
            image: "https://weberp-api.com/images/" + value.slikaPath, 
            opis: value.opis,
            tekst: value.tekst,
          }));
        })
      );
  }

  public getSliderMobile(): Observable<slider[]> {
    return this.httpClient
      .get<any>(this.url + "GetImagesMobile")
      .pipe(
        map((data) => {
          return data?.map((value: any) => ({
            image: "https://weberp-api.com/images/" + value.slikaPathMobile,
            opis: value.opis,
            tekst: value.tekst,
          }));
        })
      );
  }
}
