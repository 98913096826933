import { NgxPaginationModule } from 'ngx-pagination';
import { GroupService } from "./components/shared/services/group.service";
import { ManufacturerService } from "./components/shared/services/manufacturer.service";
import { ArticleService } from "./components/shared/services/article.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from "ngx-img-zoom";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { MainComponent } from "./components/main/main.component";
import { MatDialogModule } from "@angular/material/dialog";

import { AppRoutingModule } from "./app-routing.module";
import { ShopModule } from "./components/shop/shop.module";
import { SharedModule } from "./components/shared/shared.module";
import { ColorOptionsComponent } from "./components/color-options/color-options.component";
import { HeaderComponent } from "./components/shared/header/header.component";
import { filterHeaderProducts } from "./components/shared/header/header.pipe";
import { filterHeaderSixProducts } from "./components/shared/header-six/header-six.pipe";
import { PopUpComponent } from "./components/popup/pop-up.component";
import { ClickedOutsideDirective } from './components/directives/clicked-outside.directive';
import { CommonModule } from '@angular/common';

 



@NgModule({
  declarations: [
    AppComponent,
    // DemoComponent,
    MainComponent,
    ColorOptionsComponent,
    filterHeaderProducts,
    filterHeaderSixProducts,
    PopUpComponent,
    ClickedOutsideDirective,
   
    
  ],
  imports: [
    NgxSpinnerModule,
    NoopAnimationsModule,
    BrowserModule,
    SharedModule,
    ShopModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxImgZoomModule,
    MatDialogModule,
    NgxPaginationModule,
    CommonModule
    
  ],
 
  providers: [GroupService, ManufacturerService, ArticleService],
  bootstrap: [AppComponent],
  exports: [
    ClickedOutsideDirective
    // Other exports
  ]
})
export class AppModule {}
