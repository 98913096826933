import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categories-furniture',
  templateUrl: './categories-furniture.component.html',
  styleUrls: ['./categories-furniture.component.sass']
})
export class CategoriesFurnitureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
