import { Component, OnInit, Input } from "@angular/core";
import {
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { slide } from "src/app/components/shared/services/slide.service";

@Component({
  selector: "app-main-carousel-food",
  templateUrl: "./main-carousel-food.component.html",
  styleUrls: ["./main-carousel-food.component.scss"],
})
export class MainCarouselComponentFood implements OnInit {
  @Input("slides") slides: Array<any> = []; 
  contentLoaded = false;
  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
  };

  isMobile = false;
  constructor(public HttpClient: HttpClient, private _slide: slide) {}

  ngOnInit() {
    this.isMobile = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ].some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
    //this.GetSlider();
   // console.log("isMobile", this.isMobile);
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
    if (!this.isMobile) {
      this._slide.getSlider().subscribe((res) => {
        this.slides = res;
        //console.log("this.slides", this.slides);
      });
    } else {
      this._slide.getSliderMobile().subscribe((res) => {
        this.slides = res;
       // console.log("this.slides", this.slides);
      });
    }
  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: true,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      speed: 500,
      effect: "slide",
      updateOnImagesReady: true,
    };
  }
}
