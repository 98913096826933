import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { branchAndMerge } from '@angular-devkit/schematics';
import { environment } from '../../../../environments/environment';
import { filterHeaderProducts } from '../header/header.pipe';
import { Router } from '@angular/router';

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency : string = 'KM';
  public catalogMode : boolean = false;
  public filterHeaderProducts = new filterHeaderProducts();
  
  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";
  public products1: Product[] = [];

  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar,public _router:Router,) {
   this.compareProducts.subscribe(products => products = products)
  }

  

  private productsNovi(): Observable<Product[]> {
    return this.httpClient.get<any>(environment.baseUrl + 'Artikli/GetNoviProizvodi').pipe(map((data) => {
      return data?.map((value:any) => ({
        id: value.idArtikla,
        name: value.nazivArtikla,
        price: value.cijena, 
        salePrice: value.cijena,
        discount: value.cijenaHH,
        pictures: [value.slikaPath], 
        type: value.tipProizvoda,
        shortDetails: '',
        description: value.opisArtikla,
        stock: value.kolicina,
        state: null,
        newPro: null,
        brand: value.nazivProizvodjaca,
        brandId: value.idProizvodjaca,
        sale: null,
        category: value.nazivGrupe,
        tags: [],
        colors: [],
        isTopProizvod:value.isTopProizvod,
        isNoviProizvod:value.isNoviProizvod
      }));
    }))
  }
  private productsTop(): Observable<Product[]> {
    return this.httpClient.get<any>(environment.baseUrl + 'Artikli/GetTopProizvodi').pipe(map((data) => {
      return data?.map((value:any) => ({
        id: value.idArtikla,
        name: value.nazivArtikla,
        price: value.cijena, 
        salePrice: value.cijena,
        discount: value.cijenaHH,
        pictures: [value.slikaPath], 
        type: value.tipProizvoda,
        shortDetails: '',
        description: value.opisArtikla,
        stock: value.kolicina,
        state: null,
        newPro: null,
        brand: value.nazivProizvodjaca,
        brandId: value.idProizvodjaca,
        sale: null,
        category: value.nazivGrupe,
        tags: [],
        colors: [], 
        isTopProizvod:value.isTopProizvod,
        isNoviProizvod:value.isNoviProizvod
      })); 
    }))
  }
private products(): Observable<Product[]> {
    return this.httpClient.get<any>(environment.baseUrl + 'Artikli/GetAllProducts').pipe(map((data) => {
      return data?.map((value:any) => ({
        id: value.idArtikla,
        name: value.nazivArtikla,
        price: value.cijena, 
        salePrice: value.cijena,
        discount: value.cijenaHH,
        pictures: [value.slikaPath], 
        type: value.tipProizvoda,
        shortDetails: '',
        description: value.opisArtikla,
        stock: value.kolicina,
        state: null,
        newPro: null,
        brand: value.nazivProizvodjaca,
        brandId: value.idProizvodjaca,
        sale: null,
        category: value.nazivGrupe,
        tags: [],
        colors: [],
        isTopProizvod:value.isTopProizvod,
        isNoviProizvod:value.isNoviProizvod
      }));
    }))
  }
  public banners(): Observable<any[]>{
    return this.httpClient.get<any[]>(this.url);
  }


    // Get Banners
    public getBanners() {
      return this.banners();
    }

    // Get Banners
    public getProducts(): Observable<Product[]> {
      return this.products();
    }

    public getNoviProducts(): Observable<Product[]> {
      return this.productsNovi();
    }
    public getTopProducts(): Observable<Product[]> {
      return this.productsTop();
    }

      // Get Products By Id
  public getProduct(id: number): Observable<Product> {
    return this.products().pipe(map(items => {
      return items.find((item: Product) =>
        { return item.id === id; });
      }));
    // return this.products.find(product=> product.id === id);

    // return this.httpClient.get<Product>(this._url + 'product-' + id + '.json');
  }


        /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

// Get Compare Products
public getComapreProducts(): Observable<Product[]> {
  const itemsStream = new Observable(observer => {
    observer.next(products);
    observer.complete();
  });
  return <Observable<Product[]>>itemsStream;
}

// If item is aleready added In compare
public hasProduct(product: Product): boolean {
  const item = products.find(item => item.id === product.id);
  return item !== undefined;
}

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    return this.products().pipe(map(items => { 
      return items.find((item: any) => { 
        return item.name.replace(' ', '-') === slug; 
      }); 
    }));
  }

 // Add to compare
 public addToCompare(product: Product): Product | boolean {
  let message, status;
  var item: Product | boolean = false;
  if (this.hasProduct(product)) {
    item = products.filter(item => item.id === product.id)[0];
    const index = products.indexOf(item);
    this.snackBar.open('The product  ' + product.name + ' already added to comparison list.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

  } else {
    if(products.length < 4)
      products.push(product);
      message = 'The product ' + product.name + ' has been added to comparison list.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

  }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
}

// Removed Product
public removeFromCompare(product: Product) {
  if (product === undefined) { return; }
  const index = products.indexOf(product);
  products.splice(index, 1);
  localStorage.setItem("compareItem", JSON.stringify(products));
}

   // Get Products By category
   public getProductByCategory(category: string): Observable<Product[]> {
    return this.products().pipe(map(items =>
       items.filter((item: Product) => {
       // console.log('test');
         if(category == 'all')
            return item
            else
            return item.category === category;

       })
     ));
  }

  public getProductByBrand(brand: string): Observable<Product[]> {
    return this.products().pipe(map(items =>
       items.filter((item: Product) => {
       // console.log('test');
         if(brand == 'all')
            return item;
            else
            return item.brand === brand;

       })
     ));
  }
  public listaProizvoda: Product[] = [];
  public searchValue: string;
  public  handleSearchInput1(event) {
    this.searchValue = event.target.value;
    this.listaProizvoda = this.products1.filter((product) => {
      const name = product.name?.toLocaleLowerCase();
      
      return this.searchValue === '' || name.indexOf(this.searchValue.toLocaleLowerCase()) > -1;
    });
  //  console.log('this.listaProizvoda', this.listaProizvoda);
  }


  public onEnter() {
  //  console.log('Handle enter click.');
   // console.log('sr', this.listaProizvoda);

    const searchParameter = JSON.stringify(this.searchValue);

    this._router.navigate(['/home/products/all/left-sidebar'], { 
      queryParams: {
        searchParameter
      }
    });
  }

  

}
