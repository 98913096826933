import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductDialogComponent } from '../../products/product-dialog/product-dialog.component';
import {  SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-carousel-three',
  templateUrl: './product-carousel-three.component.html',
  styleUrls: ['./product-carousel-three.component.scss']
})
export class ProductCarouselThreeComponent implements OnInit {
  contentLoaded = false;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();

 @Input('product') product: Array<Product> = [];
 public productsNovi:Array<Product>=[];
 public config: SwiperConfigInterface = {};

  constructor(private cartService: CartService, private productsService: ProductService, private wishlistService: WishlistService, private dialog: MatDialog, private router: Router,private httpClient:HttpClient) { }
  // @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  private products(): Observable<Product[]> {
    return this.httpClient.get<any>(environment.baseUrl + 'Artikli/GetNoviProizvodi').pipe(map((data) => {
      return this.productsNovi=data?.map((value:any) => ({
        id: value.idArtikla,
        name: value.nazivArtikla,
        price: value.cijena, 
        salePrice: value.cijena,
        discount: value.cijenaHH,
        pictures: [value.slikaPath], 
        type: value.tipProizvoda,
        shortDetails: '',
        description: value.opisArtikla,
        stock: value.kolicina,
        state: null,
        newPro: null,
        brand: value.nazivProizvodjaca,
        sale: null,
        category: value.nazivGrupe,
        tags: [],
        colors: []
      }));
    }))
  }
  ngOnInit() {
    setTimeout(() => {
      this.contentLoaded = true;
    //  console.log('IN APP LOG', this.product);
    }, 3000);
    this.products();
  }
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    }
  }

  // Add to cart
  public addToCart(product: Product,  quantity: number = 1) {
    this.cartService.addToCart(product,quantity);
   // console.log(product, quantity);
  }

  // Add to wishlist
  public addToWishlist(product: Product) {
    this.wishlistService.addToWishlist(product);
 }

  // Add to compare
  public addToCompare(product: Product) {
    this.productsService.addToCompare(product);
 }


public openProductDialog(product){
  let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog',
  });
  dialogRef.afterClosed().subscribe(product => {
    if(product){
      this.router.navigate(['/products', product.id, product.name]);
    }
  });
}

}
