import { Component, OnInit, ViewChild, HostListener,ElementRef, AfterViewInit, OnDestroy } from '@angular/core'; 
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { Router } from '@angular/router';
import {MatSidenav} from '@angular/material/sidenav';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-header-six',
  templateUrl: './header-six.component.html',
  styleUrls: ['./header-six.component.scss']
  
})
export class HeaderSixComponent implements OnInit,AfterViewInit,OnDestroy {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @ViewChild('sidenavContainer', { static: false }) sidenavContainer: ElementRef;
  private unlistener: () => void;
  ngAfterViewInit() {
    this.unlistener = this.renderer.listen('document', 'click', (event: Event) => {
      this.onDocumentClick(event);
    });
  }
  ngOnDestroy() {
    if (this.unlistener) {
      this.unlistener();
    }
  }
  onDocumentClick(event: Event) {
    const clickedInside = this.sidenavContainer.nativeElement.contains(event.target);
   // console.log('Clicked inside:', clickedInside);
    if (!clickedInside && this.sidenav.opened) {
   //   console.log('Clicked outside, closing sidenav');
      this.sidenav.close();
    }
  }

  onCloseSidenav() {
    this.sidenav.close();
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.handleEnterClick();
    }
  }

  public sidenavMenuItems:Array<any>;

  public currencies = ['USD', 'EUR'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;
  public products: Product[] = [];
  public filteredList: Product[] = [];
  searchValue: string;
  public displayProductsWrap: Boolean = false;

  public hideProductsModal() {
    setTimeout(() => { this.displayProductsWrap = false; }, 200);
  }

  public shoppingCartItems: CartItem[] = [];

  constructor(private productService: ProductService, private cartService: CartService,public _router:Router, private elementRef:ElementRef,private renderer:Renderer2) {
    this.cartService.getItems().subscribe(shoppingCartItems =>
      {
      this.shoppingCartItems = shoppingCartItems
    }
    );
  }

  ngOnInit() {
    this.productService.getProducts()
    .subscribe(
      (product: Product[]) => {
        this.products = product;
        this.filteredList = product;
      }
    ) 

  }
  public handleSearchInput(event) {
    this.searchValue = event.target.value;

    this.filteredList = this.products.filter((product) => {
      const name = product.name?.toLocaleLowerCase();
      
      return this.searchValue === '' || name.indexOf(this.searchValue.toLocaleLowerCase()) > -1;
    });

   // console.log('this.filteredList', this.filteredList);
  }

  public handleEnterClick() {
  //  console.log('navigate.', this.searchValue);
  //  console.log('list.', this.filteredList);
    this._router.navigate(['/home/products/all/left-sidebar'], { 
      queryParams: {
        searchParameter: this.searchValue
      }
    });
  }
}








  





