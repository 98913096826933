import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ManufacturerService } from "src/app/components/shared/services/manufacturer.service"; 
import { Brand } from "../../../../modals/brand.model";
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router } from "@angular/router";


@Component({
  selector: 'app-brand-home',
  templateUrl: './brand-home.component.html',
  styleUrls: ['./brand-home.component.scss']
})

export class BrandHomeComponent implements OnInit {
  @Input() brand: Brand;
  slideIndex = 0;
  public config: SwiperConfigInterface = {};
  constructor(private httpClient: HttpClient, private manufacturerService: ManufacturerService, private router: Router) { }

  ngOnInit() {
    this.GetMethod();
    
  }
  public brands: any[] = [];
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    }
  }
 
  public GetMethod() {
    this.manufacturerService.getManufacturers().subscribe((data: any) => {
      this.brands = data;
     // console.log("brandovi", this.brands);
    });
  }

  public handleBrandClick(brand) {
    this.router.navigate(['/home/products/all/left-sidebar'], { 
      queryParams: {
        brandParameter: brand.idProizvodjaca
      }
    });
  }

}
