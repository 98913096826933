import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ArticleService {
  
  readonly url = environment.baseUrl + "Artikli/";
  constructor(private http: HttpClient) {}

  public getHappyHourProducts() {
    return this.http.get(this.url + "GetHappyHourProizvode");
  }
}
