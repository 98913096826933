import { Directive,ElementRef,EventEmitter,HostListener,Output } from '@angular/core';

@Directive({
  selector: '[ClickedOutside]'
})
export class ClickedOutsideDirective {

  constructor(private elementRef:ElementRef) { }

  @Output() clickOutside = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
  //  console.log('Clicked inside:', clickedInside); // Debugging statement
    if (!clickedInside) {
  //    console.log('Clicked outside'); // Debugging statement
      this.clickOutside.emit();
    }
  }
}
