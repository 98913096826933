import { Component, OnInit, Input, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartService } from '../services/cart.service';
import { Observable } from 'rxjs';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-shopping-widgets',
  templateUrl: './shopping-widgets.component.html',
  styleUrls: ['./shopping-widgets.component.scss']
})
export class ShoppingWidgetsComponent implements OnInit {
  @ViewChild('menu') menu: ElementRef;
  
  products: Product[];
  indexProduct: number;

  public sidenavMenuItems:Array<any>;

  @Input() shoppingCartItems: CartItem[] = [];

  constructor(private cartService: CartService, public productService: ProductService, private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.menu && this.menu.nativeElement) { // Add this guard
      const clickedInsideMenu = this.menu.nativeElement.contains(event.target);
      const clickedOnMenuTrigger = this.elementRef.nativeElement.contains(event.target);
      
      if (!clickedInsideMenu && !clickedOnMenuTrigger) {
        // Close the menu here
      }
    }
  }

  ngOnInit() {
  }
  public updateCurrency(curr) {
    this.productService.currency = curr;
  }


  public removeItem(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

}
