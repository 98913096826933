import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ManufacturerService } from "src/app/components/shared/services/manufacturer.service"; 

@Component({
  selector: "app-brands",
  templateUrl: "./brands.component.html",
  styleUrls: ["./brands.component.sass"],
})
export class BrandsComponent implements OnInit {
  // // brands: string[] = ['all', 'Lenovo', 'Dell', 'Dell', 'Lg', 'Samsung'];
  // brands: string[] = ['all', 'Brand-1', 'Brand-2', 'Brand-3', 'Brand-4', 'Brand-5'];
  //
  @Output() brandChanged = new EventEmitter();
  constructor(private httpClient: HttpClient, private manufacturerService: ManufacturerService) {}

  ngOnInit() {
    this.GetMethod();
  }
  public brands: any[] = [];

  public GetMethod() {
    this.manufacturerService.getManufacturers().subscribe((data: any) => {
      this.brands = data;
    //  console.log("brandovi", this.brands);
    });
  }

  brendSelect(event) {
    this.brandChanged.emit(event.value);
  }
}
