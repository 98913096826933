import { Component, OnInit } from "@angular/core";
import { ProductService } from "src/app/components/shared/services/product.service";
import { ActivatedRoute, Params, QueryParamsHandling } from "@angular/router";
import { Product, ColorFilter } from "src/app/modals/product.model";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ArticleService } from "src/app/components/shared/services/article.service";
import { Router } from "@angular/router";
import { MatExpansionPanel } from '@angular/material/expansion';
@Component({
  selector: "app-product-left-sidebar",
  templateUrl: "./product-left-sidebar.component.html",
  styleUrls: ["./product-left-sidebar.component.scss"],
})
export class ProductLeftSidebarComponent implements OnInit {
  public sidenavOpen: boolean = true;
  public animation: any; // Animation
  public sortByOrder: string = ""; // sorting
  public page: any;
  public tagsFilters: any[] = [];
  public viewType: string = "grid";
  public viewCol: number = 25;
  public filterForm: FormGroup;
  public colorFilters: ColorFilter[] = [];
  public items: Product[] = [];
  public allItems: Product[] = [];
  public products: Product[] = [];
  public allItemsInitial: Product[] = [];
  public tags: any[] = [];
  public colors: any[] = [];
  public getValue: any;
  public initialSearchParam: string;
  public initialBrandParam: string;
  enteredSearchValue: string = "";
  public name: Product[] = [];
  
  togglePanel(panel: MatExpansionPanel) {
    panel.toggle();
  }
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private router: Router
  ) {
    this.route.params.subscribe((params: Params) => {
      const category = params["category"];

      if (category) { // is there path to go to this page without category?, including all? (if we ever want to go to this page without category, should we use all as default?)
        this.productService.getProducts().subscribe((products) => { // with subscribe here we are forced to wait for products to be fetched before we can do anything else, so fetching data in ngOninit is not needed since it will not wait to fetch data before doing other things, and also why we need search filter inside subscribe
          this.allItemsInitial = products;
          const filteredProducts = products.filter(
            (value) => value.category === category
          );
          this.allItems = category === "all" ? products : filteredProducts;
          this.products = this.allItems;
          // this.products = products.slice(0.8); // why is this here?
          // this.getTags(products); // what does this do?
          // this.getColors(products); // also this?
          
          this.route.queryParams.subscribe((params: any) => {
         //   console.log("PARAMS: ", params);
            this.initialSearchParam = params.searchParameter || "";
            this.initialBrandParam = params.brandParameter || "";

            if (this.initialSearchParam) {
              this.allItems = this.products.filter((item: Product) => {
                return (
                  item.name
                    .toLowerCase()
                    .indexOf(this.initialSearchParam.toLowerCase()) > -1
                );
              });
            }

            if (this.initialBrandParam) {
              this.allItems = this.products.filter((item: Product) => Number(item.brandId) === Number(this.initialBrandParam));
            }            
          });
        });
      }
    });
  }

  ngOnInit() {
   // console.log(this.productService.searchValue);
    // if(this.initialSearchParam=='')
    // this.GetMethod();
  }

  // not used???, if we are gonna always go to this page by some category does if(category) in constructor make sense?
  public GetMethod() { 
    this.articleService.getHappyHourProducts().subscribe((data) => {
    //  console.log(data);
    });

    this.productService.getProducts().subscribe((products) => {
      this.allItems =
        this.initialSearchParam == ""
          ? products
          : products.filter(
              (product) =>
                product.name
                  .toLowerCase()
                  .indexOf(this.initialSearchParam.toLowerCase()) > -1
            );

      this.products = products;
      this.getTags(products);
      this.getColors(products);
    });
  }

  // Get current product tags
  public getTags(products) {
    var uniqueBrands = [];
    var itemBrand = Array();
    products.map((product, index) => {
      if (product.tags) {
        product.tags.map((tag) => {
          const index = uniqueBrands.indexOf(tag);
          if (index === -1) uniqueBrands.push(tag);
        });
      }
    });
    for (var i = 0; i < uniqueBrands.length; i++) {
      itemBrand.push({ brand: uniqueBrands[i] });
    }
   // console.log(itemBrand);
    this.tags = itemBrand;
  }

  // Get current product colors
  public getColors(products) {
    var uniqueColors = [];
    var itemColor = Array();
    products.map((product, index) => {
      if (product.colors) {
        product.colors.map((color) => {
          const index = uniqueColors.indexOf(color);
          if (index === -1) uniqueColors.push(color);
        });
      }
    });
    for (var i = 0; i < uniqueColors.length; i++) {
      itemColor.push({ color: uniqueColors[i] });
    }
    this.colors = itemColor;
  }

  public changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = "fadeIn";
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = "fadeOut";
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags;
    this.animation == "fadeOut" ? this.fadeIn() : this.fadeOut(); // animation
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
    this.animation == "fadeOut" ? this.fadeIn() : this.fadeOut(); // animation
  }

  // Initialize filetr Items
  public filterItems(): Product[] { // not used???
    return this.items.filter((item: Product) => {
      const Colors: boolean = this.colorFilters.reduce((prev, curr) => {
        // Match Color
        if (item.colors) {
          if (item.colors.includes(curr.color)) {
            return prev && true;
          }
        }
      }, true);
      const Tags: boolean = this.tagsFilters.reduce((prev, curr) => {
        // Match Tags
        if (item.tags) {
          if (item.tags.includes(curr)) {
            return prev && true;
          }
        }
      }, true);
      return Colors && Tags; // return true
    });
  }

  public onPageChanged(event) {
    this.page = event;
    this.allItems;
    window.scrollTo(0, 0);
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    this.allItems = this.allItemsInitial.filter((item: Product) => {
      return item.price >= price.priceFrom && item.price <= price.priceTo;
    });
    this.products = this.allItems;
    //console.log(this.products);
  }

  // In app search

  private searchTimeout = null;

  public searchParameter: String = ""; 
  public handleSearchChange(event) {
    const searchParam = event.target.value?.toLocaleLowerCase();

    //console.log("Key up", searchParam);

    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.allItems = this.allItemsInitial.filter((product) => {
        const productName = product.name?.toLocaleLowerCase();
        return searchParam === "" || productName.indexOf(searchParam) > -1; 
      });
      this.products = this.allItems;
     // console.log(this.products,'sad') 
    }, 100);
  }

  onBrendsChanged(newBrend) {
    //console.log("Filter brand", newBrend);
   // console.log("all produce", this.products);

    this.allItems =
      newBrend === "all"
        ? this.allItemsInitial
        : this.allItemsInitial.filter(
            (item: any) => item.brand === newBrend.nazivProizvodjaca
          );
    this.products = this.allItems;
  }
}
