import { Component, OnInit  } from "@angular/core";
import { GroupService } from "src/app/components/shared/services/group.service";


@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.sass"],
})
export class CategoriesComponent implements OnInit {
  
  constructor(private groupService: GroupService) {}

  public categories: any[] = [];

  public GetMethod() {
    this.groupService.getGroups().subscribe((data: any) => {
      this.categories = data;
    //  console.log("categorije", this.categories);
    });
  }

  ngOnInit() {
    this.GetMethod();
  }
  
}
