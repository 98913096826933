import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterHeaderProducts'
})

export class filterHeaderSixProducts implements PipeTransform {

  transform(values: any[], ...args: unknown[]): any[] {
   // console.log('VALUES: ', values);
   // console.log('args: ', args);
    return values.filter(v => v.name < 18);
  }
 
}