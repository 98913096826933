import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";


@Injectable()
export class GroupService {
  readonly url = environment.baseUrl + "Grupe/";
  constructor(private http: HttpClient) {}

  public getGroups() {
    return this.http.get(this.url + "GetGrupe");
  }

}
