import { Component, OnInit, ViewChild, HostListener,ElementRef, AfterViewInit, OnDestroy } from '@angular/core'; 
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';
import { SidebarMenuService } from '../sidebar/sidebar-menu.service';
import { AppSettings, Settings } from '../services/color-option.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ColorOptionsComponent } from '../../color-options/color-options.component';
import { Router,NavigationExtras  } from '@angular/router';
import { filterHeaderProducts } from './header.pipe';
import { Renderer2 } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { MatSidenav } from '@angular/material/sidenav';

@Component({  
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      state('*', style({
        opacity: 1
      })),
      transition('void <=> *', animate('300ms ease-in-out')),
    ])
  ]
  
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  @ViewChild('sidenavContainer', { static: false }) sidenavContainer: ElementRef;
  private unlistener: () => void;
  ngAfterViewInit() {
    this.unlistener = this.renderer.listen('document', 'click', (event: Event) => {
      this.onDocumentClick(event);
    });
  }
  ngOnDestroy() {
    if (this.unlistener) {
      this.unlistener();
    }
  }
  onDocumentClick(event: Event) {
    const clickedInside = this.sidenavContainer.nativeElement.contains(event.target);
   // console.log('Clicked inside:', clickedInside);
    if (!clickedInside && this.sidenav.opened) {
    //  console.log('Clicked outside, closing sidenav');
      this.sidenav.close();
    }
  }

  onCloseSidenav() {
    this.sidenav.close();
  }
  logoShow=true;
 @HostListener('window:scroll',['$event'])
 onWindowScroll(event:any){
  if(window.pageYOffset>=300){
    this.logoShow=false;
  }
  else
  this.logoShow=true;
 }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.handleEnterClick();
    }
  }
  public sidenavMenuItems:Array<any>;
  public currencies = ['USD', 'EUR'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;

  public products: Product[] = [];

  public allItems: Product[]=[];
  public displayProductsWrap: Boolean = false;
  router: any;
  public hideProductsModal() {
    setTimeout(() => { this.displayProductsWrap = false; }, 200);
  }

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];
  public settings: Settings;

  public filterHeaderProducts = new filterHeaderProducts();

  

  constructor(public productService: ProductService, private cartService: CartService, public appSettings:AppSettings, private _router:Router, public elementRef:ElementRef,private renderer: Renderer2) {
    this.settings = this.appSettings.settings;
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
   
  }
  
  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];

    this.productService.getProducts()
    .subscribe(
      (product: Product[]) => {
        this.products = product;
        this.filteredList = product;
      }
    )  
  }

  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }
  public filteredList: Product[] = [];
  searchValue: string;
  public  handleSearchInput(event) {
    this.searchValue = event.target.value;
    this.filteredList = this.products.filter((product) => {
      const name = product.name?.toLocaleLowerCase();
      
      return this.searchValue === '' || name.indexOf(this.searchValue.toLocaleLowerCase()) > -1;
    });
    //console.log('this.filteredList', this.filteredList);
  }


  public handleEnterClick() {
  //  console.log('navigate.', this.searchValue);
  //  console.log('list.', this.filteredList);
    this._router.navigate(['/home/products/all/left-sidebar'], { 
      queryParams: {
        searchParameter: this.searchValue
      }
    });
  }

}
